<template>
  <div class="w-50">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <p class="text-muted font-weight-semi-bold">
          Enter your Email
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <b-form-group>
          <label>Email</label>
          <b-form-input
            v-model="$v.form.email.$model"
            placeholder="Email"
            :state="validateState('email')"
            class="form-control form-control-solid h-auto py-5 px-6"
            aria-describedby="input-2-live-feedback"
          />
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            href="javascript:void(0)"
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
            @click="cancel"
          >
            Cancel
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Send
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { FORGOT_PASSWORD } from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    cancel() {
      this.$router.push({ name: "login" });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$bvToast.toast("please enter an email", {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        const data = {
          email: this.form.email,
        };
        setTimeout(() => {
          this.$store.dispatch(FORGOT_PASSWORD, data).then(({ data }) => {
            this.$bvToast.toast(data.message, {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            this.form = {
              email: null,
            };

            this.$nextTick(() => {
              this.$v.$reset();
            });
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      }
    },
  },
};
</script>
